import React from "react";
// import {Link} from "react-router-dom";
import {getProject, icons} from "../../data/projects";

import Footer from "../../components/footer/footer.component";

import {ProjectThumbnail, ProjectThumbnailGrid} from "../../components/project-thumbnail/project-thumbnail.component";

import "./projects-all.styles.scss";

import Select from 'react-select'

import { Power4, TimelineMax } from "gsap/all";

import gsap from 'gsap';

import {animateScroll as scroll} from 'react-scroll';

import ScrollToTop from "../../components/scroll-to-top/scroll-to-top.component"

const projects = getProject();


let stacks = [];
icons.forEach(icon => {
    stacks.push(icon.name);
});

class Projects extends React.Component {
    constructor() {
        super();
        this.state = {
            projects: projects,
            stacks: stacks,
            filter: [],
            sort: "newest",
            showSortAndFilter: false,
            listView: true
        };
        this.projectListRef= null;
        this.tl = null;

        this.anchorLinkRef = null;
        this.anchorLinkTl = null;

        this.downArrowsRef = [];
        this.downArrowsTl = null;

        this.pageRef = null;
        this.pageTl = null;

        this.filterRef = null;

        this.sortFunctions = {
            newest: function(a,b) {return b.year - a.year;},
            oldest: function(a,b) {return a.year - b.year;},
            name: function(a,b) {if (a.projectName > b.projectName) return 1; return -1}
        }

    }

    updateSort = (sortByThis) => {
        let sorted = [].concat(this.state.projects);
        // First sort by name, then sort based on user selection
        sorted.sort(this.sortFunctions["name"]).sort(this.sortFunctions[sortByThis]||this.sortFunctions[this.state.sort]);
        this.setState({sort: sortByThis, projects: sorted});
    }
    componentDidMount() {
        // this.setState({projects: projects.sort(this.sortFunctions[this.state.sort])});
        // window.dataLayer.push({event: "virtualPageview"})
        this.updateSort();
        this.tl = gsap.timeline({paused:true})
            .from(this.projectListRef, {duration: 1, y: 10, opacity: 0, ease: Power4.easeOut}).play();

        this.downArrowsTl = new TimelineMax({repeat:5})
            .staggerFromTo(this.downArrowsRef, 0.2, {opacity:1}, {opacity:0.5}, 0.2)
            .staggerFromTo(this.downArrowsRef, 0.2, {opacity:0.5}, {opacity:1}, 0.2);
    }

    componentDidUpdate() {
        this.tl.restart();
    }
    editFilter = (itemName) => {
        const newFilter = this.state.filter.slice();
        const index = newFilter.indexOf(itemName);

        // For tracking
        let label;

        if (index > -1) {
            // If the filter is already selected, remove this filter
            newFilter.splice(index ,1);
            label = "Filter remove " + itemName;
        } else { 
            // Else if the filter is not selected, add this filter
            newFilter.push(itemName);
            label = "Filter add " + itemName;
        }
        this.setState({filter: newFilter});

        window.dataLayer.push({
            event: "customEvent",
            eventCategory: "Set filter",
            eventAction: "Click",
            eventLabel: label,
        })
    }
    projectFilter = (project) => {
        for (let x = 0; x < this.state.filter.length; x++) {
            for (let y = 0; y < project.stack.length; y++) {
                if (project.stack[y].name === this.state.filter[x]) {
                    break;
                } else if ( y === project.stack.length-1) return false;
            }
        }
        return true;
    }

    renderSortBy = () => {
        const options = [
            { value: 'newest', label: 'Newest' },
            { value: 'name', label: 'Name' },
            { value: 'oldest', label: 'Oldest' },
        ]
        const colors = {
            theme: "#9BE3E1",
            themeDark: "#59ccc8",
            themeLight: "#C7ECEC",
            lightgrey: "#f1f3f4",
            dark: "#1D1D1F",
            grey: "#767676"

        }
        const customStyles = {
            option: (styles, {data, isDisabled, isFocused, isSelected }) => {
                // console.log(data, isFocused);
                return {
                    ...styles,
                    backgroundColor: isDisabled ? null : isSelected ? colors.lightgrey : isFocused ? colors.themeLight : null,
                    color: colors.dark,
                    ':active': {
                        ...styles[':active'],
                        backgroundColor: colors.theme,
                    },
                }
              },
            control: (styles, { data, isFocused, isSelected }) => {
                return {
                    ...styles,
                    border: 0,
                    boxShadow: "none",
                    width: "7em",
                    // marginLeft: "1em",
                    color: colors.grey,
                    backgroundColor: isFocused ? colors.lightgrey : null,
                    ':hover': {
                        ...styles[':hover'],
                        backgroundColor: colors.lightgrey,
                    },
                }
              },
          }
        return (<Select isSearchable={false} defaultValue={options[0]} styles={customStyles} options={options} onChange={(e) => {
            this.updateSort(e.value);
            window.dataLayer.push({
                event: "customEvent",
                eventCategory: "Set sort by",
                eventAction: "Click",
                eventLabel: "Sort by " + e.value,
            })
        }} />);
    }

    setListView = (setListView) => {
        return () => {
            this.setState({listView: setListView});
            window.dataLayer.push({
                event: "customEvent",
                eventCategory: "Set view",
                eventAction: "Click",
                eventLabel: "Set view mode to " + (setListView?"list":"grid"),
            })
        }
        
    }

    renderProjects = () => {
        let filteredProjects = this.state.projects.filter(this.projectFilter);
        if (!filteredProjects[0]) {
            return( 
                <div className="container py-5 my-5 text-center" ref={div => this.projectListRef = div}>
                    <h1 className="pt-4">No Result :(</h1>
                    <h5 className="pb-5">Try adjusting the filter</h5>
                </div>
            )
        } else if (this.state.listView) {
            return (
            <div ref={div => this.projectListRef = div}>
                {filteredProjects.map((project, index) => (this.renderProject(project, index)))}
            </div>
            )
        } else if (!this.state.listView) {
            return (
                <div className="container mt-3 mb-5">
                    <div className="row">

                        {filteredProjects.map((project, index) => {
                            return (
                                <ProjectThumbnailGrid 
                                    key={project.id} 
                                    projectObj={project} 
                                    index={index}
                                ></ProjectThumbnailGrid>
                                );
                            })}
                    </div>
                </div>
            )
        }
    }

    renderProject(projectObj, index) {
        return <ProjectThumbnail key={projectObj.id} projectObj={projectObj} index={index}></ProjectThumbnail>
    }

    render() {
        // console.log("re rendering projects componenet");
        return (
            <div ref={div => this.pageRef = div} className="projects-component background-white">
                <div className="intro text-center mt-4">
                    {/* <img className="w-100 intro-image" src={background4} alt="image"></img> */}
                    <div className="info-wrapper py-5">
                            <h5 className="thin mb-0">Proud to present</h5>
                        <h1 className="mb-0">My projects</h1>
                        <hr className="hr mt-3"></hr>
                        <p className="description">Here are some of the fun projects I have worked on. Use the sort and filter functions to find the ones that you are interested in.</p>
                        <div 
                            className={`anchor-link hover-zoom ${this.state.showSortAndFilter?"opacity-0":""}`} 
                            ref={a => this.anchorLinkRef = a}
                            onClick={() => scroll.scrollTo((this.filterRef.offsetTop)-50)}
                            data-event-category="Anchor link"
                            data-event-action="Click"
                            data-event-label="Scroll down on projects page"
                        >
                            <h3 ref={h3 => this.downArrowsRef[0] = h3} className="mb-0 chevron-arrow"><i className="fas fa-chevron-down"></i></h3>
                            <h3 ref={h3 => this.downArrowsRef[1] = h3} className="mb-0 chevron-arrow"><i className="fas fa-chevron-down"></i></h3>
                            <h3 ref={h3 => this.downArrowsRef[2] = h3} className="mb-0 chevron-arrow"   id="projects-scroll-anchor"><i className="fas fa-chevron-down"></i></h3>
                        </div>
                    </div>
                </div>
                <div className="background-white" ref={(div) => this.filterRef = div}>
                    <div className="container pt-3">

                        {/* <hr className="py-0"></hr> */}
                        <div className={`py-2 mt-2  sort-and-filter`}>

                            <div className="d-flex align-items-center">
                                <div className="text-small mr-2">
                                    Filter
                                </div>
                                    {icons.map(item => {
                                        let colored = "colored";
                                        if (!this.state.filter.includes(item.name))  colored = "";
                                        return (
                                            <div 
                                                key={item.iconClass} 
                                                className="d-inline-block filter-icon pt-1 px-1 mr-2"
                                                onClick={() => this.editFilter(item.name)}
                                            >
                                                <i className={`${item.iconClass} ${colored}`}></i>
                                            </div>
                                        );
                                    })}
                            </div>
                            <hr className="my-2"></hr>
                            <div className="d-flex text-small justify-content-between justify-content-lg-end">
                                <div className="d-flex align-items-center mr-2">
                                    <div className="mr-1">Sort by </div>
                                    {this.renderSortBy()}
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="toggle-button-wrapper">
                                        <div className={`toggle-button left ${this.state.listView ? "highlight" : ""}`} onClick={this.setListView(true)}><i className="fas fa-list"></i></div>
                                        <div className={`toggle-button ${!this.state.listView ? "highlight" : ""}`} onClick={this.setListView(false)}><i className="fas fa-grip-horizontal"></i></div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <hr className="my-0 py-0"></hr> 
                    </div>
                </div>
                {this.renderProjects()}
                <ScrollToTop/>
                <Footer/>

            </div>
        )
    }
}
export default Projects;
