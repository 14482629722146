import React from "react";
import {Link} from "react-router-dom";

import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import {CSSTransition, TransitionGroup} from "react-transition-group";

import { Power4 } from "gsap/all";
// import {CSSPlugin} from "gsap/CSSPlugin";

import gsap from 'gsap';



import ScrollToTop from "../../components/scroll-to-top/scroll-to-top.component";
import Footer from "../../components/footer/footer.component";

import bg from "../../img/background-rotated.png";
// import bg from "../../img/bg.jpg";
import {projects} from "../../data/projects";



// import "../App/App.scss";

import "./project-detail.styles.scss";


const initialState = {
    // settings: settings,
    images:[],
    stack: [],
    projectTitle: "title",
    projectID: undefined,
    projectName: "no name",
    description: "blah blah blah",
    nextID: "not found",
    links: []
}

class ProjectDetail extends React.Component {
    constructor(props) {
        super(props);
        // console.log(props.match.params.projectID);
        this.state = initialState;

        this.projectNameRef = null;
        this.projectYearRef = null;
        this.projectTitleRef = null;
        this.stackRef = [];
        this.myTween = null;
    }
    componentDidMount() {
        // console.log("componentDidMount() is called");
        this.updateProjectDetails(this.props);

    }
    componentDidUpdate(prevProps) {
        if (this.props.match && prevProps.match.params.projectID !== this.props.match.params.projectID) {
            this.updateProjectDetails(this.props);
            console.log("Updated project-detail component")
        }
    }
    updateProjectDetails(currentProps) {
        let obj = projects.find((element) => element.id === currentProps.match.params.projectID);
        this.stackRef = [];
        // console.log(this.props);
        if (!obj) {
            this.setState({projectID: null});
            return;
        }
        
        // ();
        this.setState({
            projectName:obj.projectName,
            projectID: obj.id,
            projectTitle: obj.title,
            year: obj.year,
            stack: obj.stack,
            description: obj.description,
            nextID: obj.nextID,
            images: obj.images,
            links: obj.links || initialState.links
        }, function() {
            gsap.from(this.projectNameRef, {duration: 2, letterSpacing: "1em", opacity: 0, ease: Power4.easeOut});
            gsap.from(this.projectTitleRef, {duration: 2, y: "-1em", opacity: 0, ease: Power4.easeOut});
            gsap.from(this.projectYearRef, {duration: 2, y: "-2em", opacity: 0, ease: Power4.easeOut});
            gsap.from(this.stackRef, {duration:0.3, stagger:0.3, opacity:0, rotate:-90})

        });
    }

    renderGallery() {
        const settings = {
            slidesToShow: 1,
            infinite: true,
            // centerMode:true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows:false
        }
        return (
            <div className="project-gallery container mb-5 ">
                <div className="inside">
                    <Slider className="" {...settings}>
                        {this.state.images.map(image => (
                            // console.log(image)
                            <div key={image} className="image-frame">
                                <img src={image} alt="img"></img>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        )
    }

    renderStack() {
        return this.state.stack.map((item, index) => {
            return (
                <div className="hover-zoom card-element" key={item.name} ref={div => this.stackRef.push(div)}>
                    <i className={`${item.iconClass} colored`}></i>
                </div>
            );
        })
    }
    render() {
        // this.tl.clear().pause(0);
        if (!this.state.projectID) {
            return null;
        }

        return (

            <TransitionGroup className="">
                <CSSTransition
                    key={this.state.projectID}
                    timeout={1000}
                    classNames="project"
                >
                    <div className="page project-detail">
                        <div className="project-heading text-center pt-5 mt-3" style={{
                                backgroundImage:`url(${bg})`
                            }} >
                            <h2 className="project-year mb-0"ref={div => this.projectYearRef=div}>{this.state.year}</h2>
                            <hr className="hr"></hr>
                            <h4 className="thin mb-1" ref={div => this.projectTitleRef=div}>{this.state.projectTitle}</h4>
                            <h1 className="hidetext-wrapper" ref={div => this.projectNameRef=div}> 
                                {this.state.projectName}
                            </h1>
                            <h2 className="stack d-flex justify-content-center pt-2 pb-5">
                            {this.renderStack()}
                            </h2>
                        </div>
                        {this.renderGallery()}
                        <div className="container">
                        <ScrollToTop></ScrollToTop>
                            
                            <div className="project-description">
                                <p>
                                    {this.state.description}
                                </p>
                                <div className="text-right">
                                    {this.state.links.map(link => {
                                        return (
                                        <a 
                                            rel="noreferrer" 
                                            target="_blank"     
                                            href={link.url} 
                                            key={link.name} 
                                            className="link arrow-right mr-4"
                                            data-event-category={`External link`}
                                            data-event-action="Click"
                                            data-event-label={`Go to ${this.state.projectName} - ${link.name}`}
                                        >{link.name}</a>)
                                    })}
                                </div>
                            </div>

                            <div className="d-flex justify-content-between my-5">
                                <Link className="link arrow-left" to="/projects-all">All projects</Link>
                                <Link className="link arrow-right" to={"/projects/" + this.state.nextID}>Next</Link>
                            </div>
                            
                        </div>
                        <Footer></Footer>
                    </div>
                </CSSTransition>

            </TransitionGroup>

        )
    }
}

export default ProjectDetail;