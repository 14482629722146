import React from "react";
import "./home.styles.scss";

import {Link} from "react-router-dom";

import portrait from "../../img/group1-1.png";

import Footer from "../../components/footer/footer.component";
import HorizontalScroll from "../../components/horizontal-scroll/horizontal-scroll.component";
// import TextUnit from "../text-unit/text-unit.component";

// import { TimelineLite, TweenLite } from "gsap/all";

// import {CSSPlugin} from "gsap/CSSPlugin";

import ScrollToTop from "../../components/scroll-to-top/scroll-to-top.component"


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: undefined
        }
        this.myElement = null;
        this.myTween = null;
    }

    componentDidMount(props) {
        this.setState({
            isLoaded: true
        })
        // TweenLite.from(this.myElement, 2, {opacity: 0});
    }
    render() {
        return (
            <div className="home-component transition-content">
                <div className="background background-white" >
                    <div className="container" >
                        <ScrollToTop></ScrollToTop>
                        <div className="intro">
                            <div className="image">
                            </div>
                            <div className="text pt-4 pt-lg-0">
                                {/* <div id="highlight"></div> */}
                                <p id="hello">Hello there!</p>
                                <h1 className="the-name"> My name is Dylan.</h1>
                                <div className="hr"></div>
                                <p>I am a computer science student studying at Ryerson University in Toronto, Canada.</p>
                                <p>I like to make things out of code. Ever since I discovered my interest in it, I have been learning it not only in school, but as well as on my own. </p>
                                <p><Link className="link arrow-right" to="/projects">See my projects</Link><Link className="link arrow-down" to="/projects">Scroll Down</Link></p>
                            </div>
                            <div className="text-center mobile-portrait"><img className="w-100" src={portrait} alt="the author"></img> </div>
                        </div>
                    </div>
                </div>
                <HorizontalScroll/>
                <Footer></Footer>
            </div>
        )
    }
}

export default Home;